import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "text-dark fw-bolder text-hover-primary d-block fs-6" }
const _hoisted_3 = { class: "text-dark fw-bolder text-hover-primary d-block fs-6" }
const _hoisted_4 = { class: "text-dark fw-bolder text-hover-primary d-block fs-6" }
const _hoisted_5 = {
  key: 0,
  class: "text-dark fw-bolder text-hover-primary d-block fs-6"
}
const _hoisted_6 = { class: "d-flex justify-content-end" }
const _hoisted_7 = {
  class: "btn-group btn-group-sm pb-2 pt-1",
  role: "group"
}
const _hoisted_8 = { class: "border-0 m-0" }
const _hoisted_9 = {
  colspan: "8",
  class: "p-0"
}
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "table-responsive" }
const _hoisted_12 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_13 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_14 = { class: "svg-icon svg-icon-3 svg-icon-primary" }
const _hoisted_15 = { class: "text-primary fs-5 ms-2" }
const _hoisted_16 = {
  width: "5%",
  class: "ps-2"
}
const _hoisted_17 = ["onUpdate:modelValue", "onInput"]
const _hoisted_18 = { width: "19%" }
const _hoisted_19 = { class: "ps-3 text-dark fw-bolder text-hover-primary d-block fs-6" }
const _hoisted_20 = { width: "19%" }
const _hoisted_21 = { class: "text-dark fw-bolder text-hover-primary d-block fs-7" }
const _hoisted_22 = { width: "19%" }
const _hoisted_23 = { class: "text-dark fw-bolder text-hover-primary d-block fs-7" }
const _hoisted_24 = { width: "19%" }
const _hoisted_25 = {
  key: 0,
  class: "text-dark fw-bolder text-hover-primary d-block fs-6"
}
const _hoisted_26 = {
  key: 0,
  width: "19%",
  class: "text-end pe-2"
}
const _hoisted_27 = {
  class: "btn-group btn-group-sm pe-0 pb-2 pt-1",
  role: "group"
}
const _hoisted_28 = ["onClick"]
const _hoisted_29 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconAngleDown = _resolveComponent("IconAngleDown")!
  const _component_PlusSignSvg = _resolveComponent("PlusSignSvg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("tr", {
          key: 0,
          class: "cursor-pointer bg-hover-light",
          id: `skill_collapse_${_ctx.data?.id}_toggle`,
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleCollapse && _ctx.handleCollapse(...args)))
        }, [
          _createElementVNode("td", {
            onClick: _cache[2] || (_cache[2] = ev => ev.stopPropagation())
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.order) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleEditWeight(null))),
              class: "form-control mw-75px ps-5"
            }, null, 544), [
              [_vModelText, _ctx.order]
            ])
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.data.name), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data.worker_description), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.data.company_description), 1)
          ]),
          _createElementVNode("td", null, [
            (_ctx.data.document_type_needed)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.data.document_type_needed.name), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.$can('skill_edit', 'all') && _ctx.$can('skill_delete', 'all'))
            ? (_openBlock(), _createElementBlock("td", {
                key: 0,
                onClick: _cache[5] || (_cache[5] = ev => ev.stopPropagation())
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.$can('skill_edit', 'all'))
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('open-edit-modal', _ctx.data))),
                          type: "button",
                          class: "btn btn-sm btn-warning"
                        }, " Edit "))
                      : _createCommentVNode("", true),
                    (_ctx.$can('skill_delete', 'all'))
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete-skill', { id: _ctx.data?.id, name: _ctx.data?.name }))),
                          class: "btn btn-sm btn-danger"
                        }, " Delete "))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("td", {
            class: _normalizeClass(_ctx.isOpen ? 'text-start' : 'text-end')
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle(_ctx.isOpen ? 'rotate: 180deg !important' : '')
            }, [
              _createVNode(_component_IconAngleDown, {
                class: _normalizeClass(_ctx.isOpen ? 'svg-icon-primary' : '')
              }, null, 8, ["class"])
            ], 4)
          ], 2)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("tr", _hoisted_8, [
      _createElementVNode("td", _hoisted_9, [
        _createElementVNode("div", {
          class: "collapse",
          id: `skill_collapse_${_ctx.index}`
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("table", _hoisted_12, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (
                  _ctx.$emit('new-sub-skill', { id: _ctx.data?.id, name: _ctx.data?.name })
                )),
                  colspan: "7",
                  class: "bg-hover-secondary"
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, [
                      _createVNode(_component_PlusSignSvg)
                    ]),
                    _createElementVNode("span", _hoisted_15, "Add new Sub-skill to " + _toDisplayString(_ctx.data.name), 1)
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data?.children, (skill) => {
                return (_openBlock(), _createElementBlock("tr", {
                  class: "bg-secondary bg-opacity-50",
                  key: skill.id
                }, [
                  _createElementVNode("td", _hoisted_16, [
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      "onUpdate:modelValue": ($event: any) => ((skill.order) = $event),
                      onInput: ($event: any) => (_ctx.handleEditWeight(skill)),
                      class: "form-control w-75px ps-5"
                    }, null, 40, _hoisted_17), [
                      [_vModelText, skill.order]
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(skill.name), 1)
                  ]),
                  _createElementVNode("td", _hoisted_20, [
                    _createElementVNode("span", _hoisted_21, _toDisplayString(skill.worker_description), 1)
                  ]),
                  _createElementVNode("td", _hoisted_22, [
                    _createElementVNode("span", _hoisted_23, _toDisplayString(skill.company_description), 1)
                  ]),
                  _createElementVNode("td", _hoisted_24, [
                    (skill.document_type_needed)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(skill.document_type_needed.name), 1))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.$can('skill_edit', 'all') && _ctx.$can('skill_delete', 'all'))
                    ? (_openBlock(), _createElementBlock("td", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          (_ctx.$can('skill_edit', 'all'))
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                onClick: ($event: any) => (_ctx.$emit('open-edit-modal', skill)),
                                type: "button",
                                class: "btn btn-sm btn-warning"
                              }, " Edit ", 8, _hoisted_28))
                            : _createCommentVNode("", true),
                          (_ctx.$can('skill_delete', 'all'))
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                onClick: ($event: any) => (
                      _ctx.$emit('delete-skill', { id: skill.id, name: skill.name })
                    ),
                                class: "btn btn-sm btn-danger"
                              }, " Delete ", 8, _hoisted_29))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ], 8, _hoisted_10)
      ])
    ])
  ], 64))
}