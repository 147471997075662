import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-8" }
const _hoisted_2 = { class: "card-body py-3 d-flex flex-column" }
const _hoisted_3 = { class: "table-responsive w-100" }
const _hoisted_4 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { colspan: "7" }
const _hoisted_8 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateEditSkillModal = _resolveComponent("CreateEditSkillModal")!
  const _component_DeleteSkillModal = _resolveComponent("DeleteSkillModal")!
  const _component_SkillsCardHeader = _resolveComponent("SkillsCardHeader")!
  const _component_SkillsTableHeader = _resolveComponent("SkillsTableHeader")!
  const _component_Skill = _resolveComponent("Skill")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CreateEditSkillModal, {
      ref: "skillModal",
      onSavedSkill: _ctx.handleSaveSkill
    }, null, 8, ["onSavedSkill"]),
    _createVNode(_component_DeleteSkillModal, {
      ref: "deleteModal",
      onDeletedSuccessfully: _ctx.handleSuccessfullDelete
    }, null, 8, ["onDeletedSuccessfully"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SkillsCardHeader, {
        onSaveWeights: _ctx.handleSaveWeights,
        saving: _ctx.savingWeight,
        "show-button": _ctx.changedWeights.length > 0,
        onNewSkill: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCreateEditModal(null)))
      }, null, 8, ["onSaveWeights", "saving", "show-button"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _createElementVNode("thead", null, [
              _createVNode(_component_SkillsTableHeader)
            ]),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (skill, index) => {
                    return (_openBlock(), _createBlock(_component_Skill, {
                      onWeightChanged: _ctx.handleWeightChange,
                      onDeleteSkill: _ctx.handleDeleteSkill,
                      onNewSubSkill: _cache[1] || (_cache[1] = 
                event =>
                  _ctx.openCreateEditModal({ parent_id: event.id, name: event.name })
              ),
                      onOpenEditModal: _ctx.openCreateEditModal,
                      index: index,
                      key: skill.id,
                      data: skill
                    }, null, 8, ["onWeightChanged", "onDeleteSkill", "onOpenEditModal", "index", "data"]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_7, [
                      _createVNode(_component_LoadingSpinner)
                    ])
                  ])
                ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Pagination, {
            currentPage: _ctx.pagination.current_page,
            totalPages: _ctx.pagination.last_page,
            onChangeCurrentPage: _ctx.handleChangePage
          }, null, 8, ["currentPage", "totalPages", "onChangeCurrentPage"])
        ])
      ])
    ])
  ], 64))
}