
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref
} from "vue";
import SkillsCardHeader from "@/components/skills/SkillsCardHeader.vue";
import SkillsTableHeader from "@/components/skills/SkillsTableHeader.vue";
import { useStore } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Skill from "@/components/skills/Skill.vue";
import CreateEditSkillModal from "@/components/skills/CreateEditSkillModal.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import DeleteSkillModal, {
  DeleteSkillData
} from "@/components/skills/DeleteSkillModal.vue";

export interface Skill {
  name: string;
  order: number | null;
  id: number;
  green_skill: number;
  parent_id: number | null;
  dresscode_id: number | null;
  children: Skill[];
  worker_description: string;
  company_description: string;
  document_type_needed: {
    id: number;
    name: string;
  } | null;
}

export interface ChangedWeights {
  id: number;
  weight: number | null;
}

export default defineComponent({
  name: "skills-view",
  components: {
    DeleteSkillModal,
    Pagination,
    CreateEditSkillModal,
    Skill,
    LoadingSpinner,
    SkillsTableHeader,
    SkillsCardHeader
  },
  setup: function() {
    const loading = ref<boolean>(false);
    const store = useStore();
    const skillModal = ref<InstanceType<typeof CreateEditSkillModal>>();
    const deleteModal = ref<InstanceType<typeof DeleteSkillModal>>();
    const changedWeights = ref<ChangedWeights[]>([]);
    const savingWeight = ref<boolean>(false);

    const pagination = computed(
      () => store.getters["SkillsModule/getPaginationData"]
    );

    const skills = computed<Skill[]>(
      (): Skill[] => store.getters["SkillsModule/skillsList"]
    );

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function fetchPrimarySkills(parentId: number | null): void {
      setLoading(true);
      store
        .dispatch("SkillsModule/fetchData", { parentId: null })
        .then(() => {
          setLoading(false);
          nextTick().then(() => {
            if (parentId) {
              const element = document.getElementById(
                `skill_collapse_${parentId}_toggle`
              );
              if (element) {
                element.click();
              }
            }
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }

    function handleChangePage(page: number): void {
      store.dispatch("SkillsModule/setPageNumber", page);
      fetchPrimarySkills(null);
    }

    onMounted((): void => {
      fetchPrimarySkills(null);
    });

    onUnmounted(() => {
      store.dispatch("SkillsModule/resetState");
    });

    function handleSaveSkill(data): void {
      console.log(data.parent_id);
      fetchPrimarySkills(data.parent_id ?? null);
    }

    function openCreateEditModal(data: Skill | null): void {
      if (skillModal.value) {
        skillModal.value?.toggleModal(true, data);
      }
    }

    function handleDeleteSkill(skill: DeleteSkillData): void {
      deleteModal.value?.toggleModal(true, skill);
    }

    function handleSuccessfullDelete(): void {
      store.dispatch("SkillsModule/setPageNumber", 1);
      fetchPrimarySkills(null);
    }

    function handleWeightChange(data: ChangedWeights): void {
      const index = changedWeights.value.findIndex(i => i.id === data.id);
      if (-1 !== index) {
        changedWeights.value.splice(index, 1);
      }
      changedWeights.value.push(data);
    }

    function handleSaveWeights(): void {
      savingWeight.value = true;
      store
        .dispatch("SkillsModule/saveWeights", { skills: changedWeights.value })
        .then(() => {
          changedWeights.value = [];
          fetchPrimarySkills(null);
          savingWeight.value = false;
        })
        .catch(() => {
          savingWeight.value = false;
        });
    }

    return {
      handleSuccessfullDelete,
      openCreateEditModal,
      handleWeightChange,
      handleDeleteSkill,
      handleSaveWeights,
      handleChangePage,
      handleSaveSkill,
      changedWeights,
      savingWeight,
      deleteModal,
      skillModal,
      pagination,
      loading,
      skills
    };
  }
});
