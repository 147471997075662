
import { defineComponent, onMounted, onUnmounted, PropType, ref } from "vue";
import { Skill } from "@/views/skills/Index.vue";
import IconAngleDown from "@/components/Icons/IconAngleDown.vue";
import { Collapse } from "bootstrap";
import PlusSignSvg from "@/components/Icons/PlusSign.vue";
export default defineComponent({
  components: { PlusSignSvg, IconAngleDown },
  emits: ["open-edit-modal", "new-sub-skill", "delete-skill", "weight-changed"],
  name: "skill",
  props: {
    data: {
      type: [Object, null] as PropType<Skill | null>,
      default: () => null
    },
    index: {
      type: Number as PropType<number>,
      default: () => 0
    }
  },
  setup: function(props, { emit }) {
    const isOpen = ref<boolean>(false);
    const collapse = ref<HTMLElement | null>(null);
    const bsElement = ref<Collapse | null>(null);
    const order = ref<number | null>(null);

    function handleCollapseShow(): void {
      isOpen.value = true;
    }

    function handleCollapseHide(): void {
      isOpen.value = false;
    }

    onMounted((): void => {
      collapse.value = document.getElementById(`skill_collapse_${props.index}`);
      if (collapse.value) {
        bsElement.value = new Collapse(collapse.value, {
          toggle: false
        });
        collapse.value?.addEventListener(
          "show.bs.collapse",
          handleCollapseShow
        );
        collapse.value?.addEventListener(
          "hide.bs.collapse",
          handleCollapseHide
        );
      }
      order.value = props.data?.order ?? null;
    });

    onUnmounted((): void => {
      if (collapse.value) {
        collapse.value?.removeEventListener(
          "show.bs.collapse",
          handleCollapseShow
        );
        collapse.value?.removeEventListener(
          "hide.bs.collapse",
          handleCollapseHide
        );
      }
    });

    function handleCollapse(): void {
      if (bsElement.value) {
        bsElement.value.toggle();
      }
    }

    function handleEditWeight(skill: Skill | null): void {
      emit("weight-changed", {
        id: skill ? skill.id : props.data?.id,
        weight: skill ? skill.order : order.value
      });
    }

    return {
      handleEditWeight,
      handleCollapse,
      bsElement,
      isOpen,
      order
    };
  }
});
