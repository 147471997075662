
/* eslint-disable @typescript-eslint/camelcase */
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Skill } from "@/views/skills/Index.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Modal } from "bootstrap";
import * as Yup from "yup";
import { useStore } from "vuex";

export interface Pagination {
  current_page: 1;
  last_page: 1;
}

interface NewSubSkill {
  parent_id: number | null;
  name: string;
}

export default defineComponent({
  name: "create-edit-skill-modal",
  components: { Form, Field, ErrorMessage },
  emits: ["saved-skill"],
  setup: function(props, { emit }) {
    const store = useStore();
    const element = ref<HTMLElement | null>(null);
    const modal = ref<Modal | null>(null);
    const formComponent = ref();
    const skillData = ref<Skill | null>(null);
    const documentType = ref<number | null>(null);
    const dresscode = ref<number | null>(null);
    const loading = ref<boolean>(false);
    const newSkillParent = ref<NewSubSkill | null>(null);
    const fieldParentId = ref<number | null>(null);
    const isEditing = ref<boolean>(false);
    const greenSkill = ref<boolean>(false);

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    const skills = computed<Skill[]>(
      (): Skill[] => store.getters["ListsModule/skills"]
    );

    const documentTypes = computed(
      () => store.getters["ListsModule/documentTypes"]
    );

    const dresscodes = computed(() => store.getters["ListsModule/dresscodes"]);

    const validationSchema = computed(() => {
      return Yup.object().shape({
        name: Yup.string()
          .required()
          .label("Name")
      });
    });

    function handleModalHide(): void {
      skillData.value = null;
      greenSkill.value = false;
      newSkillParent.value = null;
      dresscode.value = null;
      isEditing.value = false;
      fieldParentId.value = null;
      formComponent.value.resetForm();
    }

    onMounted(() => {
      if (!documentTypes.value.length) {
        store.dispatch("ListsModule/fetchDocumentTypes");
      }
      if (!dresscodes.value.length) {
        store.dispatch("ListsModule/fetchDresscodes");
      }
      if (!skills.value.length) {
        store.dispatch("ListsModule/fetchSkills");
      }
      element.value = document.getElementById("createEditSkillModal");
      if (element.value) {
        modal.value = new Modal(element.value, {});
        element.value?.addEventListener("hidden.bs.modal", handleModalHide);
      }
    });

    onUnmounted(() => {
      if (element.value) {
        element.value?.removeEventListener("hidden.bs.modal", handleModalHide);
      }
    });

    function populateForm(): void {
      if (skillData.value) {
        formComponent.value.setFieldValue("name", skillData.value.name);
        formComponent.value.setFieldValue(
          "worker_description",
          skillData.value.worker_description
        );
        formComponent.value.setFieldValue(
          "client_description",
          skillData.value.company_description
        );
        if (skillData.value.green_skill) {
          greenSkill.value = true;
        }
        if (skillData.value.dresscode_id) {
          dresscode.value = skillData.value.dresscode_id;
        }
        formComponent.value.setFieldValue("name", skillData.value.name);
        documentType.value = skillData.value.document_type_needed
          ? skillData.value.document_type_needed.id
          : null;
      }
    }

    function showModal(data: Skill | null): void {
      if (modal.value) {
        modal.value.show();
        if (data)
          if (data.id) {
            skillData.value = data;
            isEditing.value = true;
            if (data.parent_id) {
              fieldParentId.value = data.parent_id;
            }
            populateForm();
          } else {
            newSkillParent.value = {
              parent_id: data.parent_id,
              name: data.name
            };
            fieldParentId.value = newSkillParent.value.parent_id;
          }
      }
    }

    function hideModal(): void {
      if (modal.value) {
        modal.value.hide();
      }
    }

    function toggleModal(toggle: boolean, data: Skill | null): void {
      if (toggle) {
        showModal(data);
      } else {
        hideModal();
      }
    }

    function handleSubmit(data): void {
      setLoading(true);
      const payload = {
        ...data,
        document_type_needed_id: documentType.value,
        dresscode_id: dresscode.value
      };
      if (isEditing.value) {
        if (skillData.value?.parent_id !== fieldParentId.value) {
          payload.parent_id = fieldParentId.value;
        } else {
          payload.parent_id = skillData.value?.parent_id ?? null;
        }
      } else {
        if (newSkillParent.value) {
          payload.parent_id = newSkillParent.value.parent_id;
        } else if (fieldParentId.value) {
          payload.parent_id = fieldParentId.value;
        } else {
          payload.parent_id = null;
        }
      }
      payload.green_skill = greenSkill.value ? 1 : 0;
      if (isEditing.value) {
        store
          .dispatch("SkillsModule/update", {
            id: skillData.value?.id,
            values: payload
          })
          .then(() => {
            setLoading(false);
            emit("saved-skill", payload);
            toggleModal(false, null);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        store
          .dispatch("SkillsModule/createNew", payload)
          .then(() => {
            setLoading(false);
            emit("saved-skill", payload);
            toggleModal(false, null);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }

    const modalTitle = computed<string>((): string => {
      if (newSkillParent.value) {
        return "Add new sub-skill to " + newSkillParent.value.name;
      }
      if (skillData.value) {
        if (skillData.value.parent_id) {
          return "Edit sub-skill " + skillData.value.name;
        } else {
          return "Edit skill " + skillData.value.name;
        }
      }
      return "Add new skill";
    });

    return {
      validationSchema,
      newSkillParent,
      documentTypes,
      formComponent,
      fieldParentId,
      documentType,
      handleSubmit,
      toggleModal,
      modalTitle,
      dresscodes,
      setLoading,
      dresscode,
      isEditing,
      skillData,
      loading,
      skills,
      greenSkill
    };
  }
});
