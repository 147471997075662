
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Modal } from "bootstrap";
import { useStore } from "vuex";

export interface DeleteSkillData {
  id: number;
  name: string;
}

export default defineComponent({
  name: "delete-skill-modal",
  emits: ["deleted-successfully"],
  setup: function(props, { emit }) {
    const element = ref<HTMLElement | null>(null);
    const modal = ref<Modal | null>(null);
    const skill = ref<DeleteSkillData | null>(null);
    const loading = ref<boolean>(false);
    const store = useStore();

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function handleModalHide(): void {
      skill.value = null;
    }

    onMounted((): void => {
      element.value = document.getElementById("deleteSkillModal");
      if (element.value) {
        modal.value = new Modal(element.value, {});
        element.value?.addEventListener("hidden.bs.modal", handleModalHide);
      }
    });

    onUnmounted((): void => {
      if (element.value) {
        element.value?.removeEventListener("hidden.bs.modal", handleModalHide);
      }
    });

    function toggleModal(toggle: boolean, data: DeleteSkillData | null): void {
      if (toggle) {
        modal.value?.show();
        if (data) {
          skill.value = data;
        }
      } else {
        modal.value?.hide();
      }
    }

    function handleDelete(): void {
      setLoading(true);
      store
        .dispatch("SkillsModule/destroyData", skill.value?.id)
        .then(() => {
          emit("deleted-successfully");
          setLoading(false);
          toggleModal(false, null);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    return {
      handleDelete,
      toggleModal,
      loading,
      skill
    };
  }
});
