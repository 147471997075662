
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "skills-card-header",
  emits: ["save-weights", "new-skill"],
  props: {
    showButton: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    saving: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup: function() {
    return {};
  }
});
